/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import voucherCodes from "../../api/voucherCodes";

const state = {
  baseShipping: 0,
  shipping: 0,
  subtotal: 0,
  total: 0,
  discount: 0,
  credit: 0,
  preCreditTotal: 0,
  salesTax: 0,
  extraCharge: 0,
  strippedUkVat: 0,
  negotiatedDiscount: 0,
  dynamicBundleDiscount: 0,
  promotions: [],
  discountCodes: [],
  voucherFailureMessageKey: "failure-message",
  freeShipping: {}
};

const getters = {};

const actions = {
  async applyCode({ commit, dispatch }, { code, loadIntent }) {
    let response;
    if (loadIntent) {
      response = await voucherCodes.applyCodeWithIntent(code);
    } else {
      response = await voucherCodes.applyCode(code);
    }
    if (response) {
      if (response.data.success) {
        dispatch("cart/commitCartData", response.data.cart, { root: true });
      } else {
        commit("setVoucherFailureMessage", response.data.message);
        throw new Error("Invalid voucher code");
      }
    } else {
      commit("setVoucherFailureMessage", "failure-message");
      throw new Error("Invalid voucher code");
    }
  },
  async removeCode({ commit, dispatch }, { code, loadIntent }) {
    commit("setLoading", true, { root: true });
    let response;
    if (loadIntent) {
      response = await voucherCodes.removeCodeWithIntent(code);
    } else {
      response = await voucherCodes.removeCode(code);
    }
    commit("setLoading", false, { root: true });
    if (response) {
      dispatch("cart/commitCartData", response.data, { root: true });
    } else {
      // Handle error
    }
  }
};

const mutations = {
  setTotals(state, totals) {
    state.baseShipping = totals.baseShipping;
    state.shipping = totals.shipping;
    state.subtotal = totals.subtotal;
    state.total = totals.total;
    state.discount = totals.discount;
    state.promotions = totals.promotions;
    state.credit = totals.credit;
    state.salesTax = totals.sales_tax;
    state.extraCharge = totals.extra_charge;
    state.strippedUkVat = totals.strippedUkVat;
    state.preCreditTotal = totals.pre_credit_total;
    state.discountCodes = totals.discount_codes;
    state.freeShipping = totals.free_shipping;
    state.negotiatedDiscount = totals.negotiated_discount || 0;
    state.dynamicBundleDiscount = totals.dynamic_bundle_discount || 0;
  },
  setVoucherFailureMessage(state, message) {
    state.voucherFailureMessageKey = message;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
