/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = {
  newsletter: false,
  paypal: {},
  stripe: {},
  checkoutDotCom: {},
  stripeV2: {}
};

const mutations = {
  setNewsletter(state, newsletter) {
    state.newsletter = newsletter;
  },
  setPaypalDetails(state, details) {
    state.paypal = details;
  },
  setStripeDetails(state, details) {
    state.stripe = details;
  },
  setCheckoutDotComDetails(state, details) {
    state.checkoutDotCom = details;
  },
  setStripeV2Details(state, details) {
    state.stripeV2 = details;
  },
  setStripeV2Intent(state, intent) {
    if (state.stripeV2) {
      state.stripeV2.intent = intent;
    }
  },
  setBraintreeToken(state, token) {
    if (state.paypal) {
      state.paypal.token = token;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
