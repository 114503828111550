import api from "./index";

export default {
  async applyCode(code) {
    try {
      const response = await api.post("/basket/discount_codes.json", { code });
      return response;
    } catch (error) {
      return false;
    }
  },
  async applyCodeWithIntent(code) {
    try {
      const response = await api.post(
        "/basket/discount_codes.json?load_intent=true",
        { code }
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async removeCode(code) {
    try {
      const response = await api.delete(`/basket/discount_codes/${code}.json`);
      return response;
    } catch (error) {
      return false;
    }
  },
  async removeCodeWithIntent(code) {
    try {
      const response = await api.delete(
        `/basket/discount_codes/${code}.json?load_intent=true`
      );
      return response;
    } catch (error) {
      return false;
    }
  }
};
